import type {
  CSSProperties,
  FunctionComponent
} from 'react'
import type { IconCollection, Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'
import {
  useEffect,
  useState
} from 'react'

import { icons } from '../../design-tokens/iconography/icons'

import styles from './icon.module.scss'

interface IconProps {
  name: string | Icons
  className?: string
  color?: string
  rotate?: boolean
  darkMode?: boolean
  style?: CSSProperties
  withBackground?: boolean
  ariaHidden?: boolean
}

// const loadIcons = async (): Promise<IconCollection> => {
//   const module = await import('../../design-tokens/iconography/icons')
//   return module?.icons
// }

const Icon: FunctionComponent<IconProps> = (
  {
    name,
    className,
    rotate = false,
    darkMode,
    color,
    style,
    withBackground = false,
    ariaHidden
  }
) => {
  // const [icons, setIcons] = useState<IconCollection>()
  let iconName = name
  let IconSVG = null

  // useEffect(() => {
  //   loadIcons().then((icons: any) => {
  //     setIcons(icons)
  //   })
  // }, [])

  // if (icons) {
    if (darkMode && Object.keys(icons).includes(`${name}-dark`)) {
      iconName = `${name}-dark` as IconProps['name']
    }

    if (iconName in icons) {
      IconSVG = icons[iconName as Icons]
    } else {
      IconSVG = null
      console.warn(`Icon ${iconName} not found`)
    }
  // }

  return IconSVG
    ? <i
      aria-hidden={ariaHidden ? 'true' : undefined}
      className={clsx(
        styles.icon,
        rotate && styles.iconRotate,
        darkMode && styles.iconDark,
        withBackground && styles.iconWithBackground,
        className
      )}
      style={
        color
          ? { '--icon-color': color, ...style } as CSSProperties
          : style
      }
    >
      {IconSVG && <IconSVG/>}
    </i>
    : null
}

export default Icon

export type {
  IconProps
}
