import type { Thing, WebSite, WithContext } from 'schema-dts'
import type { MetaDescriptor } from '@remix-run/react'
import type { V2_ServerRuntimeMetaDescriptor } from '@remix-run/server-runtime/dist/routeModules'

import { initSeo } from 'remix-seo'

const defaultImage = 'https://images.vrt.be/width1280/2018/06/14/8c44a15b-6fbd-11e8-abcc-02b7b76bf47f.png'

const websiteStructuredData: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://sporza.be',
  publisher: {
    '@type': 'Organization',
    name: 'Sporza',
    logo: {
      '@type': 'ImageObject',
      url: 'https://images.vrt.be/w960hx/2018/05/29/2d4b9926-6351-11e8-abcc-02b7b76bf47f.jpg'
    }
  }
}

const commonTags = [
  { charset: 'utf-8' },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
  },
  {
    name: 'theme-color',
    content: '#000000'
  },
  {
    property: 'al:ios:app_store_id',
    content: '878339906'
  },
  {
    property: 'al:ios:app_name',
    content: 'Sporza'
  },
  {
    property: 'al:android:package',
    content: 'com.fwc2014.vrt.and'
  },
  {
    property: 'al:android:app_name',
    content: 'sporza'
  },
  {
    property: 'fb:page_id',
    content: '22186054270'
  },
  {
    property: 'og:site_name',
    content: 'sporza.be'
  },
  {
    property: 'og:type',
    content: 'article'
  },
  {
    property: 'og:locale',
    content: 'nl_BE'
  },
  {
    property: 'article:author',
    content: 'https://www.facebook.com/sporza/'
  },
  {
    name: 'twitter:widgets:csp',
    content: 'on'
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image'
  },
  {
    name: 'twitter:site',
    content: '@sporza'
  },
  {
    name: 'twitter:creator',
    content: '@sporza'
  },
  {
    name: 'apple-mobile-web-app-capable',
    content: 'yes'
  },
  {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'black'
  },
]

const defaultMetaData = {
  title: 'sporza'
}

const createPageTitle = (title = defaultMetaData.title) => {
  const metaTitle = [title]

  if (title.toLowerCase() !== 'sporza') {
    metaTitle.push('sporza')
  }

  return metaTitle.join(' | ')
}

const { getSeo, getSeoMeta, getSeoLinks } = initSeo({
  title: defaultMetaData.title,
  robots: {
    maxImagePreview: 'large',
  }
})

const toVersion2 = (seoMeta: MetaDescriptor, structuredData?: Thing | Thing[]): V2_ServerRuntimeMetaDescriptor[] => {
  const seoTags = []

  if (seoMeta) {
    const seoMetaKeys = Object.keys(seoMeta)
    const hasImage = seoMetaKeys.includes('image')

    if (!hasImage) {
      seoTags.push(
        {
          property: 'image',
          content: defaultImage
        },
        {
          property: 'og:image',
          content: defaultImage
        },
        {
          property: 'twitter:image',
          content: defaultImage
        },
      )
    }

    seoMetaKeys
      .forEach(metaTag => {
          switch (metaTag) {
            case 'title':
              seoTags.push(
                { [metaTag]: seoMeta[metaTag] },
                {
                  name: 'twitter:title',
                  content: seoMeta[metaTag]
                },
              )
              break
            case 'description':
              seoTags.push(
                {
                  name: [metaTag],
                  content: seoMeta[metaTag]
                },
                {
                  name: 'twitter:description',
                  content: seoMeta[metaTag]
                },
              )
              break
            case 'image':
              seoTags.push(
                {
                  property: 'image',
                  content: seoMeta[metaTag]
                },
                {
                  property: 'og:image',
                  content: seoMeta[metaTag]
                },
                {
                  property: 'twitter:image',
                  content: seoMeta[metaTag]
                },
              )
              break
            case 'publicationDate':
              seoTags.push(
                {
                  itemprop: 'article:published_time',
                  content: seoMeta[metaTag]
                }
              )
              break
            case 'permalink':
              seoTags.push(
                {
                  property: 'og:url',
                  content: seoMeta[metaTag]
                },
                {
                  tagName: 'link',
                  rel: 'canonical',
                  href: seoMeta[metaTag]
                },
                {
                  property: 'al:ios:url',
                  content: `sporza://article?url=${encodeURIComponent(seoMeta[metaTag] as string)}`
                },
                {
                  property: 'al:android:url',
                  content: `sporza://article?url=${encodeURIComponent(seoMeta[metaTag] as string)}`
                },
                {
                  name: 'apple-itunes-app',
                  content: `app-id=878339906, app-argument=${seoMeta[metaTag]}`
                }
              )
              break
            default:
              seoTags.push({ [metaTag]: seoMeta[metaTag] })
          }
        }
      )
  }

  if (structuredData){
    if (Array.isArray(structuredData)) {
      structuredData.forEach(data => seoTags.push({ 'script:ld+json': data }))
    } else {
      seoTags.push({ 'script:ld+json': structuredData })
    }
  }

  seoTags.push({ 'script:ld+json': websiteStructuredData })

  return [
    ...commonTags,
    ...seoTags
  ]
}

export {
  createPageTitle,
  toVersion2,
  getSeo,
  getSeoMeta,
  getSeoLinks
}
